import React from 'react';
// import Radium from 'radium';
// import { connect } from 'react-redux';
import {
 BrowserRouter as Router,
 // Route,
 // Redirect,
 // Switch
} from 'react-router-dom';

import HeaderComponent from './components/header';
import FooterComponent from './components/footer';
import LandingPage from './containers/landingPage';

const Routes = () => {
  return (
    <Router>
      <div style={{ flex: 1, backgroundColor: '#222222' }}>
        <HeaderComponent />
        <LandingPage />
        <FooterComponent />
      </div>
    </Router>
  );
};

export default Routes;
