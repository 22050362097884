import React from 'react';
import './index.scss';
// import PropTypes from 'prop-types';

import AnimateImg from '../animateImg';
// import AnimateBtn from '../animateBtn';

const AnimatedContainer = (props) => {
  const {
    img,
    title,
    // buttonLink,
    // buttonText
} = props;

  return (
    <div className="animate-all">
      <AnimateImg className="img" img={img} />

      <div className="slider-container">
        <h1 className="slide-text bounce">{title}</h1>
        <div className="divider lightSpeedIn" />
        {/**<AnimateBtn
          className="btn-appear"
          link={buttonLink}
        >
          {buttonText}
        </AnimateBtn>**/}
      </div>
    </div>
  );
};

// AnimateImg.propTypes = {
//   img: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   buttonLink: PropTypes.string.isRequired,
//   buttonText: PropTypes.string.isRequired
// };

export default AnimatedContainer;
