import React from 'react';
import moment from 'moment';

const styles = {
  container: {
    padding: 30,
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#222222',
    color: 'white'
  }
};

export default class FooterComponent extends React.Component {
  state = {};

  render() {
    const Year = moment().year();
    return (
      <div style={styles.container}>
        &copy; {Year} Tyche Labs Inc.
      </div>
    );
  }
}
