import React from 'react';
import Welcome from '../components/welcome';


const styles = {
  background: {
    backgroundColor: '#000000',
    flex: 1,
  },
  containers: {
    backgroundColor: '#000000',
    padding: 20,
  }
};


class LandingPage extends React.Component {
  render() {
    return (
      <div style={styles.background}>
        <Welcome />
      </div>
    );
  }
}





export default LandingPage;
