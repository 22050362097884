import React from 'react';
// import android from '../images/android.png';
import headerImage from '../images/header.png';

const styles = {
  container: {
    color: '#000000',
    backgroundColor: '#000000',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    flex: 0.10,
  }
};

class HeaderComponent extends React.Component {
  state = {};

  render() {
    // const { activeItem } = this.state
    return (
      <div style={styles.container} className="App-header">
        <img
          src={headerImage}
          alt="header logo"
          align="left"
          width="380"
        />
      </div>
    );
  }
}


export default HeaderComponent;
