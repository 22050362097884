import React from 'react';
// import { Icon, Card, Image, Transition } from 'semantic-ui-react';
import AnimatedContainer from './animatedContainer/AnimatedContainer';

import lightbulb from '../images/lightbulb.jpg';

// const styles = {
//   cardContent: {
//     flexDirection: 'row',
//     display: 'flex',
//     alignItems: 'center',
//     alignSelf: 'center',
//     justifyContent: 'space-between',
//     flex: 1,
//     height: 100
//   },
//   text: {
//     color: 'white',
//     textAlign: 'center',
//     paddingBottom: 20
//   }
// };


const TITLE = 'Something exciting coming soon...';
const BUTTON_TEXT = 'LEARN MORE';

export default class Welcome extends React.Component {
  render() {
    return (
      <div style={{ flex: 1 }}>
        <AnimatedContainer
          img={lightbulb}
          title={TITLE}
          buttonLink="google.com"
          buttonText={BUTTON_TEXT}
        />
      </div>
    );
  }
}
