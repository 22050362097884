import {
  SUBMITTING_FORM,
  SUBMITTING_FORM_SUCCESS,
  SUBMITTING_FORM_FAILED
} from '../actions/types';


const INITIAL_STATE = {
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SUBMITTING_FORM:
    return { ...state, loading: true };
  case SUBMITTING_FORM_SUCCESS:
    return INITIAL_STATE;
  case SUBMITTING_FORM_FAILED:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
  }
};
